@import "styles/variables";

.Cars {
  //padding-top: 3rem;

  .carCard {

    :global(.badge) {
      font-size: 100%;
    }
    :global(.card-body) {
      background: rgba(255, 255, 255, 0.075);
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
    //.photo {
    //  filter: saturate(0.8) grayscale(.2);
    //  box-shadow: inset 0 -10px 50px -5px rgba(0,0,0,.1);
    //  background-color: transparent;
    //}
    .placeholder {
      width: 100%;
      height: auto;
      //color: #364859;
      background-color: transparent;
    }
    .cardBody {
      min-height: 10rem;
      display: flex;
      flex-flow: column nowrap;
    }
    .carNumber {
      font-size: 2.25rem;
      font-weight: bold;
    }
    //.driverName {
    //  font-size: 1.125rem;
    //}
    .carLink {
      color: white;
      text-decoration: none;
    }
    .carUpdated {
      font-size: 0.875rem;
      color: $text-muted;
    }
    .carOwner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: auto;

      .ownerPic {
        margin-right: .5rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;

        .ownerPlaceholder {
          background: transparent;
        }
      }

      .ownerName {
        font-size: .875rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }
  }
}