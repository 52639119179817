.AlertManager {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5rem;
  margin-right: 1rem;

  :global(.alert) {
    box-shadow: 0 0 10px 0 rgba(0,0,0,.25);

    :global(.close) {
      z-index: 1;
    }
  }

  .countDown {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem 0.71875rem;
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 113px;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 1px;
      stroke: rgba(255,255,255,.4);
      fill: none;
      animation: countdown 3.5s linear forwards;
      animation-duration: inherit;
    }
  }

  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: 113px;
    }
  }
}