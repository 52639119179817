@import "styles/variables";

.SmartImage {
  position: relative;

  .photo {
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    background-color: $table-accent-bg;
    transition: opacity .2s;
  }
  .placeholder {
    width: 100%;
    height: auto;
    color: transparentize($custom-dark, .5);
    background-color: $table-accent-bg;
    transition: opacity .2s;
  }

  &:global(.loading) {
    .placeholder {
      color: transparentize($custom-dark, .8);

      & :global(.loader){
        display: inline !important;
        fill: rgba(255,255,255,.02);
        animation: loader 1s ease infinite;

        @keyframes loader {
          from {transform: translateY(-20%)}
          to {transform: translateY(100%)}
        }
      }
    }
  }
}