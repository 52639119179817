@import "styles/variables";

.CarPage {
  .carPhoto {
    height: 100%;
    overflow: hidden;
    border: 2px solid rgba(0,0,0,.2);
    border-right: none;

    .carImage {
      height: 100%;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    .carPlaceholder {
      height: 100%;
    }
  }

  .carSpecs {
    padding: 1.5rem 2rem;
    background: transparentize($body-bg, .6);
    margin: 0;
    box-shadow: inset 100px 0 100px -100px rgba(0,0,0,.25);
    border-left:2px solid rgba(0,0,0,.2);

    p:last-child {
      margin-bottom: 0;
    }
    .specIcon {
      flex-shrink: 0;
      margin: .25rem 1rem .25rem 0;
      color: $text-muted;
    }
  }
  .bg {
    filter: grayscale(.2) blur(2px) brightness(0.5) saturate(0.5);
    background-position: 0 50%;
  }
  .carBlock {
    border-radius: 3px;
    overflow: hidden;
  }
  .carInfo {
    padding: 1.5rem 0;
    margin-top: -2rem;
    background: rgba(255,255,255,.05);
  }
}