.alert {
  font-size: 0.875rem;
  margin-bottom: 0;
  border-radius: 0;

  code {
    color: inherit;
  }
}

.ContentPage {

  .regulations {
    font-size: 0.875rem;

    ul, ol {
      padding: 0;
    }
  }
}