.Loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top:0;
  left:0;

  &:not(.inline) {
    position: absolute;
    background: rgba(0,0,0,.1);
  }

  &.inline {
    height: 8rem;
  }
}
