.Header {
  font-size: 15px;

  :global(.brand-title.brand-title-live:before) {
    font-size: 75%;
    line-height: 75%;
    font-weight: bold;
    margin-right: 1rem;
  }

  :global(.dropdown-menu.show) {
    top: 3.25rem;
  }

  .langSelect {
    :global(.dropdown-menu) {
      min-width: unset;
    }

    :global(.nav-link):after {
      vertical-align: middle;
    }

    .lang {
      display: flex;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .UserMenu {
    :global(.nav-link):after {
      vertical-align: middle;
    }
  }
}