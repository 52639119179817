.Referees {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem 0;

  .referee {
    height: 4rem;
    background: #4E5D6C;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: .25rem;
    overflow: hidden;
    margin: 1rem;
    border-radius: 3px;

    .refereePhoto {
      align-items: flex-end;
      align-self: stretch;
      display: flex;
      padding: 0;
    }
    .refereeLink {
      text-decoration: none;
      color: inherit;
    }
    .placeholder {
      width: 100%;
      height: auto;
      //color: #364859;
    }
  }

  .pointTable {
    border: 1px solid rgba(0, 0, 0, 0.15);

    th, td {
      font-size: 0.75rem;
      padding: 0.5rem 0.25rem;
      text-align: center;

      &:nth-child(2n+1) {
        &:not(:first-child) {
          border-left: 1px solid rgba(0, 0, 0, 0.15);
        }
        background: rgba(255, 255, 255, 0.05);
      }
    }
  }
}