.Footer {
  background-color: #364859;
  position: absolute;
  width: 100%;
  bottom: 0;

  .Logo {
    :global(.white) { fill: #abb6c2; }
    :global(.orange) { fill: #868e96; }
  }

  .socialIcon {
    width: 24px;
    height: 24px;
    margin: 5px;

    svg {
      fill: #abb6c2;
    }
  }
}