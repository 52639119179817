.StandingsTable {
  padding: 2.5rem 1rem;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    tr th{
      vertical-align: middle;
      text-align: center;

      &[colspan="2"] {
        border-bottom: none;
        padding: 0.25rem 0.75rem;
        font-size: 12px;
      }
    }
    tr.sub th {
      border-top: none;
      padding: 0.25rem 0.75rem;
      font-size: 13px;
    }
    tr td {
      text-align: center;
    }
    .left {
      text-align: left;
    }
    .round {
      width: 50px;
    }
  }
}