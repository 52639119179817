@import 'styles/variables';

.Bracket {
  display: flex;
  margin-bottom: 2rem;
}

$pairHeight: 4rem;
$pairMargin: .75rem;

.rowLink {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.round {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;

  .roundInner {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
  }

  .connectors {
    //background: red;
    position: relative;
    flex: .01 1 2.25rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;

    .connector {
      &:before {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        border: thin solid rgba(255,255,255,.2);
        border-left: none;
      }
      &:after {
        content: "";
        display: inline-block;
        box-sizing: border-box;
        width: 50%;
        height: 50%;
        border-bottom: thin solid rgba(255,255,255,.2);
        vertical-align: top;
      }
    }
  }

  &.round:nth-child(1) .connectors .connector {
    height: $pairHeight + $pairMargin;
  }
  &.round:nth-child(2) .connectors .connector {
    height: $pairHeight * 2 + $pairMargin * 2;
  }
  &.round:nth-child(3) .connectors .connector {
    height: $pairHeight * 4 + $pairMargin * 4;
  }
  &.round:nth-child(4) .connectors .connector {
    height: $pairHeight * 8 + $pairMargin * 8;
  }

  &.round2 {
    .roundInner {
      justify-content: center;
    }
    .pair {
      &:not(:last-child) {
        $margin: $pairHeight / 2 + $pairMargin * 1.5;
        margin-top: $pairHeight + $margin;
        margin-bottom: $margin;
      }
      &:last-child {
        opacity: 0.85;
      }
    }
    .connectors .connector {
      display: none;
    }
  }
}

.pair {
  color: #EBEBEB;
  margin: $pairMargin/2 0;

  &.bye {
    opacity: .5;
  }

  .row {
    background: #4E5D6C;
    border: 1px solid rgba(255,255,255,.15);
    border-left-width: 3px;
    padding: .25rem;
    height: $pairHeight / 2;
    margin: 0;

    .number {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &:first-child {
      border-radius: 3px 3px 0 0;
      border-bottom-width: 0;
    }
    &:last-child {
      border-radius: 0 0 3px 3px;
    }
    &:first-child:last-child {
      border-radius: 3px;
      border-bottom-width: 1px;
    }

    &.win {
      border-left-color: $primary;
      color: $primary;
    }
  }
}