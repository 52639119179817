@import "styles/variables";

.Requests {
  .author {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: transparentize($dark, .25) !important;
    background-color: transparent !important;

    :global(.loader) {
      fill: rgba(255,255,255,.05) !important;
    }
  }
  .actions {
    //padding: .25rem;
    vertical-align: middle;
    text-align: center;

    .actionsMenu {
      opacity: .5;
      transition: opacity .2s;
    }
    .actionsButton {
      font-size: 85%;
      white-space: nowrap;
    }
  }

  table td {
    padding: 1.25rem 1rem;
  }

  tr:hover {
    .actionsMenu {
      opacity: 1;
    }
  }

  .legend {
    font-size: .875rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
}

.RequestPopup {
  h6 {
    border-bottom: 1px solid rgba(255,255,255, 0.15);
    margin-bottom: 1rem;
    padding-bottom: .5rem;
  }
  .section {
    margin-bottom: 1.5rem;
  }
  .author {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: $text-muted;
  }
  .approver {
    display: flex;
    align-items: flex-start;
    margin-bottom: .5rem;

    .avatar {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .Heroshot {
    svg{
      color: transparent !important;
    }

    .placeholder {
      background-image:
              linear-gradient(45deg, $table-accent-bg 25%, transparent 25%, transparent 75%, $table-accent-bg 75%, $table-accent-bg),
              linear-gradient(45deg, $table-accent-bg 25%, transparent 25%, transparent 75%, $table-accent-bg 75%, $table-accent-bg);
      background-size:2rem 2rem;
      background-position:0 0, 1rem 1rem;
      color: #4E5D6C;
      transition: all .2s;
      visibility: visible !important;
      opacity: 1 !important;
    }

    &:not(:global(.loading)):hover {
      .image {
        opacity: .15;
      }
      .placeholder {
        background-image:
                linear-gradient(45deg, $table-hover-bg 25%, transparent 25%, transparent 75%, $table-hover-bg 75%, $table-hover-bg),
                linear-gradient(45deg, $table-hover-bg 25%, transparent 25%, transparent 75%, $table-hover-bg 75%, $table-hover-bg);
        color: #4E5D6C;

        path {
          stroke-width: 30px;
          stroke: rgba(255,255,255,.1);
          stroke-dasharray: 2200;
          stroke-dashoffset: -770;
        }
      }
    }
  }
}

.reason {
  resize: none;
}