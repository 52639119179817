@import "styles/variables";

.info, .alert {
  font-size: 0.875rem;
}
.pills {
  font-size: .875rem;
  flex-direction: column;
}
.preview {
  overflow: scroll;
  padding: 1rem;
  border: 1px dotted rgba(255,255,255,.5);
  border-radius: 3px;
  background: rgba(255,255,255,.1);
  //margin-bottom: 1.5rem;

  max-height: 700px;
  min-height: 200px;
  height: 300px;
  resize:vertical;
  font-size: 0.875rem;

  ul, ol {
    padding: 0;
  }
}