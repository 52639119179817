.StagesList {
  background: rgba(255, 255, 255, 0.05);
  padding: 1.5rem 0;
  margin-top: -2rem;

  .title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .brackets {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
      display: inline-block;
      padding: 0.5rem 1.5rem;
      text-align: center;
      flex: 1;

      &:not(:first-child) {
        border-left:1px solid #4E5D6C;
      }

      .round {
        font-size: 0.875rem;
        text-transform: uppercase;
        text-align: center;
        display: block;
        margin-bottom: 1rem;
      }
    }
  }
}