@import "styles/variables";

.Profile {

  .Heroshot {
    &.invalid {
      border: 1px solid $danger;
    }

    &:global(.loading) .placeholder,
    .placeholder {
      color: $body-bg;
    }

    .placeholder.preview {
      background-image:
              linear-gradient(45deg, $table-accent-bg 25%, transparent 25%, transparent 75%, $table-accent-bg 75%, $table-accent-bg),
              linear-gradient(45deg, $table-accent-bg 25%, transparent 25%, transparent 75%, $table-accent-bg 75%, $table-accent-bg);
      background-size:2rem 2rem;
      background-position:0 0, 1rem 1rem;
      color: transparent;
      transition: all .2s;
      visibility: visible !important;
      opacity: 1 !important;
    }

    &:not(:global(.loading)):hover {
      .photoPreview {
        opacity: .15;
      }
      .placeholder.preview {
        background-image:
                linear-gradient(45deg, $table-hover-bg 25%, transparent 25%, transparent 75%, $table-hover-bg 75%, $table-hover-bg),
                linear-gradient(45deg, $table-hover-bg 25%, transparent 25%, transparent 75%, $table-hover-bg 75%, $table-hover-bg);
        color: $body-bg;

        path {
          stroke-width: 30px;
          stroke: rgba(255,255,255,.1);
          stroke-dasharray: 2200;
          stroke-dashoffset: -770;
        }
      }
    }
  }

  .alert {
    font-size: 0.875rem;
  }

  :global(.react-datepicker-wrapper) {
    display: block;
  }

  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--keyboard-selected)
  {
    background-color: $primary;
  }

  .editHeroshot {
    position: absolute;
    left: .5rem;
    bottom: .5rem;

    :global(.dropdown-toggle:after) {
      vertical-align: middle;
    }
  }

  .bio {
    resize: none;
  }

  .requirements {
    padding-left: 1.5rem;
    list-style-type: " — ";
  }

  .localeTabs {
    position: absolute;
    right: 0;

    :global(.nav-link) {
      padding: 0.25rem .5rem;
      font-size: 0.875rem;
      border-radius: 3px;
    }
  }
}

.ProfileRules {
  font-size: 0.875rem;

  .list {
    list-style-type: none;
    li {
      padding: .25rem 0;
      :global(.icon-liOk),
      :global(.icon-liNo) {
        margin-left: -2rem;
        margin-right: 1rem;
        font-size: 1rem !important;
      }
      :global(.icon-liNo) {
        color: $danger;
      }
      :global(.icon-liOk) {
        color: $success;
      }
    }
  }
}

.ProfileForm {
  max-height: 100rem;
  overflow: hidden;
  transition: max-height .5s;

  &.collapsed {
    position: relative;
    max-height: 17.5rem;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      //box-shadow: inset 0 -150px 100px -100px $body-bg;
      background: linear-gradient(0deg, rgba(43,62,80,1) 0%, rgba(43,62,80,0.5) 20%, rgba(43,62,80,0) 40%, rgba(43,62,80,0) 100%);
      z-index: 1;
    }
    .collapseButton {
      position: absolute;
      bottom: 0;
      left:0;
      z-index: 2;
      opacity: .5;
      transition: opacity .2s;
      box-shadow: none !important;
      &:hover {
        opacity: 1;
        background-color: $secondary;
      }
    }
  }

}
.carSelect {
  max-width: 20rem;
}

