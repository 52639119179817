@import "styles/variables";

.LastResult {
  padding: 0.5rem 1rem;
  margin: 0.5rem 1rem;
  color: #EBEBEB;
  //background-color: #2B3E50;
  //border: 1px solid #4E5D6C;
  margin-bottom: .5rem;

  .bracket {
    display: block;
    text-decoration: none;
    color: inherit;
  }

  .row {
    background: #4E5D6C;
    border: 1px solid rgba(255,255,255,.15);
    border-left-width: 3px;
    padding: .25rem;

    .number {
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &:first-child {
      border-radius: 3px 3px 0 0;
      border-bottom-width: 0;
    }
    &:last-child {
      border-radius: 0 0 3px 3px;
    }
    &:first-child:last-child {
      border-radius: 3px;
      border-bottom-width: 1px;
    }

    &.win {
      border-left-color: $primary;
      color: $primary;
    }
  }
}