.Leaderboard {
  padding-top: 3rem;

  :global(.container) {
    position: relative;
    z-index: 1;
  }

  &.promo {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 5px;
      box-sizing: content-box;
      top: -5px;
      left: -5px;
      background-size: cover;
      filter: grayscale(.2) blur(2px) brightness(0.5) saturate(0.5);
      z-index: 0;
      opacity: .8;
      box-shadow: inset 0 20px 100px 0 rgba(0,0,0,.3), inset 0 -20px 100px 0 rgba(0,0,0,.3);
    }

    &.promo-standings:before {
      background-image: url('/images/promo/udrc1.jpg');
    }
    &.promo-stage:before {
      background-image: url('/images/promo/udrc2.jpg');
    }
  }

  .leaderCard {
    .cardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    :global(.badge) {
      font-size: 100%;
    }
    :global(.card-body) {
      background: rgba(255, 255, 255, 0.075);
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
    .placeholder {
      height: auto;
      padding: 0;
      //color: #364859;
      background-color: transparent;
    }
    .photo {
      z-index: 1;
      filter: saturate(0.8) grayscale(.2);
      box-shadow: inset 0 -10px 50px -5px rgba(0,0,0,.1);
    }
    &:global(.bg-primary) .placeholder {
      color: #a74f13;
    }
    .tailNumber {
      text-align: right;
    }
    .carNumber {
      font-size: 3rem;
      font-weight: bold;
    }
    .driverLink {
      color: white;
      text-decoration: none;
    }
  }
}
