@import "styles/variables";

.img {
  width: 100px;
}

.Garage {
  .pic {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1.5rem;
  }
}

.alert {
  font-size: 0.875rem;
}

.CarForm {

  .localeTabs {
    position: absolute;
    right: 0;

    :global(.nav-link) {
      padding: 0.25rem .5rem;
      font-size: 0.875rem;
      border-radius: 3px;
    }
  }

  .story {
    resize: none;
  }

  .CarSpecs {
    //border: 1px dotted $text-muted;
    //padding: 1rem;
    //border-radius: 3px;

    .specsHead {
      margin-bottom: 1rem;
      //padding-bottom: .5rem;
      border-bottom: 1px solid rgba(255,255,255,.1);
    }

    .specsFoot {
      border-top: 1px solid rgba(255,255,255,.1);
      padding-top: .5rem;
      margin-top: .5rem;
    }

    .specsLabel {
      vertical-align: middle;
      border-bottom: 1px dotted currentColor;
    }

    .delimiter {
      width: 100%;
      margin: .5rem 0 1rem;
      border-top-color: rgba(255,255,255,.1);
      border-top-style: dotted;
    }

    & :global(.form-row):last-child {
      margin-bottom: 0 !important;
    }
  }

  .CarPhotos {
    position: sticky;
    top: 6rem;

    .invalid {
      border: 1px solid $danger;
    }

    :global(.loading) .placeholder,
    .placeholder {
      color: $body-bg !important;
    }

    .editPhoto {
      position: absolute;
      left: .5rem;
      bottom: .5rem;

      :global(.dropdown-toggle:after) {
        vertical-align: middle;
      }
    }

    .requirements {
      padding-left: 1.5rem;
      list-style-type: " — ";
    }
  }
}

.CarsList {
  .carPlaceholder {
    width: 100%;
    height: auto;
    color:$custom-dark;
    margin-bottom: -1px;
    margin-top: 1px;

    :global(.text) {
      display: none;
    }
    &:global(.empty .text) {
      display: block;
    }
  }
  .disabled {
    opacity: 0.8;

    .carImage:not(:global(.transparent)) {
      filter: saturate(0.5);
      opacity: .5;
    }
  }
  .specialBadge {
    position: absolute;
    right: .5rem;
    top: .5rem;
    text-decoration: none;

    &.sold {
      background-color: transparentize($secondary, .2);
    }
  }

  .carLink {
    color: white;
    text-decoration: none;
  }
  .carFooter {
    padding: 1.25rem;
    font-size: .875rem;
  }
  .editCar {
    position: absolute;
    left: .5rem;
    bottom: .5rem;

    :global(.dropdown-toggle:after) {
      vertical-align: middle;
    }
  }
}

