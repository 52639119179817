.Drivers {
  //padding-top: 3rem;

  .driverCard {

    :global(.badge) {
      font-size: 100%;
    }
    :global(.card-body) {
      background: rgba(255, 255, 255, 0.075);
      border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
    .photo {
      filter: saturate(0.8) grayscale(.2);
      box-shadow: inset 0 -10px 50px -5px rgba(0,0,0,.1);
      background-color: transparent;
    }
    .placeholder {
      width: 100%;
      height: auto;
      //color: #364859;
      background-color: transparent;
    }
    .cardBody {
      min-height: 7rem;
    }
    .carNumber {
      font-size: 2.25rem;
      font-weight: bold;
    }
    .driverName {
      font-size: 1.125rem;
    }
    .driverLink {
      color: white;
      text-decoration: none;
    }
  }
}