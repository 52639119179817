@import "styles/variables";
@import "styles/core";

.AuthPopup {
  :global(.modal-header) {
    align-items: center;
  }
  :global(.modal-body) {
    overflow: hidden;
  }
  :global(.modal-footer) {
    a {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
  :global(a:hover) {
    color: darken($primary,5%);
  }

  .form {
    @include media-breakpoint-up(sm) {
      padding: 1rem 3rem;
    }
  }
  .fieldset {
    & > *:last-child {
      margin-bottom: 0;
    }
  }
  .or {
    margin: 0 -4rem 1rem;
    display: flex;
    justify-content: stretch;
    align-items: center;
    text-transform: uppercase;

    &:before, &:after {
      content: "";
      background: rgba(255,255,255,.2);
      flex: 1;
      height: 1px;
    }
    small {
      padding: 1rem;
      color: #abb6c2;
      user-select: none;
    }
  }
  .options {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-top: -.5rem;
    margin-bottom: 1.5rem;
  }
}