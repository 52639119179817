.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px !important;

  width: 1em;
  height: 1em;

  fill: currentColor;

  box-sizing: content-box !important;

  &:global(.icon-small) {
    font-size: 18px !important;
  }

  &:global(.icon-large){
    font-size: 32px !important;
  }

  :global(.ionicon) {
    stroke: currentColor;
  }

  :global(.ionicon-fill-none) {
    fill: none;
  }

  :global(.ionicon-stroke-width) {
    stroke-width: 32px;
  }

  :global(.icon-inner),
  :global(.ionicon),
  svg {
    display: block;

    height: 100%;
    width: 100%;
  }

  //:global(.ionicon), :global(.fill) {
  //  fill: currentColor;
  //}
  //
  //:global(.ionicon-fill-none) {
  //  fill: none;
  //}
  //:global(.ionicon-stroke-width) {
  //  stroke: currentColor;
  //}
}