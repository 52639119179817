@import "./variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "./bootswatch";
@import "~react-datepicker/dist/react-datepicker.css";

html, body {
  width: 100%;
  height: 100%;
}

body {
  font-weight: 300;
}

#root {
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 175px;
}

.brand-pill {
  height: auto;
  border: none;
  background: $primary;
  transform: skewX(-4deg);
  border-radius: 2px;
  padding: 0.5rem 1rem
}

.brand-title {
  display: flex;
  align-items: start;
  margin-left: 5px;

  &:before {
    content: " ";
    display: inline-block;
    height: 1em;
    border-left: 8px solid $primary;
    transform: skewX(-8deg);
    margin-right: 1.25rem;
    border-radius: 1px;
    margin-top: .1em;
  }

  b {
    display: inline-block;
    height: 1em;
    border-left: 3px solid rgba(255,255,255,.5);
    transform: skewX(-8deg);
    margin: 0 1.25rem;
    border-radius: 1px;
    margin-top: .1em;

  }

  &-live {
    &:before {
      content: "live";
      height: auto;
      border: none;
      background: $primary;
      transform: skewX(-4deg);
      border-radius: 2px;
      font-size: 50%;
      padding: 0.5rem;
      font-weight: bold;
      animation: blink 1s infinite;
    }

    @keyframes blink {
      0% {
        color: rgba(255,255,255,1);
      }
      50% {
        color: rgba(255,255,255,.5);
      }
      100% {
        color: rgba(255,255,255,1);
      }
    }
  }
}

.brand-pills {
  .nav-link {
    transform: skewX(-3deg);
    border-radius: 2px;
  }
}
.brand-badge {
  transform: skewX(-3deg);
  border-radius: 2px;
}

.card, .btn, .form-control, .alert {
  border-radius: 3px;
}

hr {
  border-top-color: rgba(0,0,0,.15)
}

.select {
  color: $secondary;
  font-weight: 400;
}


.promo-header {
  position: relative;
  overflow: hidden;
  height: 12rem;
  padding-top: 4rem;
  display: flex;
  align-items: center;

  & > * {
    z-index: 1;
  }

  & .brand-title {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: content-box;
    top: -5px;
    left: -5px;
    background-size: cover;
    filter: grayscale(.2) blur(2px) brightness(0.5) saturate(0.5);
    z-index: 0;
    opacity: .8;
    box-shadow: inset 0 20px 100px 0 rgba(0,0,0,.3), inset 0 -20px 100px 0 rgba(0,0,0,.3);
    background-position: 50% 20%;
  }

  &.drivers:before {
    background-image: url('/images/promo/drivers.jpg');
  }

  &.cars:before {
    background-image: url('/images/promo/cars.jpg');
  }

  &.regulations:before {
    background-image: url('/images/promo/regulations.jpg');
  }
}

.todo {
  &:after {
    content: "todo";
    display: inline-block;
    font-size: 0.625rem;
    vertical-align: top;
    margin: 0 0.75rem;
    text-transform: uppercase;
    background: #F40076;
    padding: 0.1rem 0.2rem 0.2rem;
    border-radius: 3px;
    text-shadow: 0 0 5px #000;
    opacity: .5;
    transition: opacity .2s;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.tbd {
  text-decoration: line-through !important;
  text-decoration-color: rgba(244, 0, 118, .75) !important;
}

.form-label.required {
  &:after {
    content: "*";
    display: inline-block;
    white-space: nowrap;
    //font-size: 1.25em;
    vertical-align: top;
    margin: 0 0.25rem;
    //text-transform: uppercase;
    //background: #F40076;
    //padding: 0.1rem 0.2rem 0.2rem;
    //border-radius: 3px;
    //text-shadow: 0 0 5px #000;
    //opacity: .5;
    //transition: opacity .2s;
    //box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    color: $danger;
    font-size: 0.875rem;
  }
}
.form-label.invalid {
    border-color: #d9534f;
    padding-right: 0.75rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.25rem center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.dropdown-menu {
  padding: 0.25rem 0;
  border-radius: 3px;
}
.dropdown-item {
  padding: 0.375rem 1rem;
}
.dropdown-divider {
  margin: 0.25rem 0;
}

.transparent {
  opacity: 0;
}

.text-medium {
  font-size: 0.875rem;
}