@import "styles/variables";

.driverPromo {
  padding-top: 3rem;
  padding-bottom: 1rem;
  overflow: hidden;

  .driverName {
    font-weight: bold;
    z-index: 1;
    position: relative;
    max-width: 55%;
  }
  .driverInfo {
    height: 20rem;
    position: relative;

    .driverStats {
      position: absolute;
      width: 100%;
      bottom: 0;

      .title {
        font-size: 13px;
      }
      .value {
        font-size: 2.5rem;
        color: $primary;
        font-weight: bold;
      }
    }

  }

  .driverHeadshot {
    position: absolute;
    display: flex;
    align-items: flex-end;
    bottom: -1rem;
    right: 0;
    width: 45%;
    height: 100%;

    .heroshot {
      width: 100%;
      z-index: 1;
      filter: saturate(0.8) grayscale(.2);

      img {
        width: 100%;
      }
      .photo,
      .placeholder {
        background: none;
      }
      .placeholder {
        color: transparentize($body-bg, .8);
      }
    }

    .number {
      font-size: 460px;
      line-height: 420px;
      font-weight: bold;
      position: absolute;
      right: 0;
      top: -20%;
      color: rgba(255,255,255,.1);
      user-select: none;
    }
  }


}

.specList {
  p:last-child {
    margin-bottom: 0;
  }
  .specIcon {
    flex-shrink: 0;
    margin: .25rem 1rem .25rem 0;
    color: $text-muted;
  }
}


.socialIcon {
  width: 24px;
  height: 24px;
  margin: 5px 10px;

  &:first-child {
    margin-left: 0;
  }

  svg {
    fill: #abb6c2;
  }
}

.carPlaceholder {
  width: 100%;
  height: auto;
  color:$custom-dark;
}

