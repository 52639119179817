.CountDown {
  //position: absolute;
  //top: 0;
  //right: 0;
  width: 1rem;
  height: 1rem;
  //margin: 0.5rem 0.71875rem;
  //transform: rotateY(-180deg) rotateZ(-90deg);
  position: relative;
  display: inline-block;
  vertical-align: middle;

  .outer {
    width: 125%;
    height: 125%;
    display: block;
    position: absolute;
    left: -12.5%;
    top: -12.5%;
    transform: rotateY(-180deg) rotateZ(-90deg);

  }

  circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 3px;
    stroke: rgba(255,255,255,.5);
    fill: none;

    transition: stroke-dashoffset 2s;
  }

  .inner {
    position: absolute;
    left: 0;
    font-size: .625rem;
    top: 0;
    opacity: .75;
    width: 100%;
    height: 100%;
    font-weight: bold;
    text-align: center;
    line-height: 1rem;
  }
}