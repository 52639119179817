@import "styles/variables";

.MemberMenuContainer {
  position: sticky;
  top: 6rem;
}

.MemberMenu {
  border-radius: 3px;
  overflow: hidden;
  font-size: 0.875rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;

  .groupTitle {
    opacity: .75;
    font-size: 12px;
    background: #364859;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem !important;
    color: $text-muted;

    &:hover {
      background: #364859 !important;
    }
  }

  :global(.list-group-item) {
    padding: 0.625rem 1.25rem;
    border-color: transparent;
    border-top-color: rgba(255,255,255, 0.1);
    border-bottom-color: rgba(255,255,255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;

    :global(.badge) {
      font-size: 0.75rem;
    }
  }
  :global(.list-group-item.active) {

    border-color: transparent;
    border-top-color: rgba(255,255,255, 0.1);
    border-bottom-color: rgba(255,255,255, 0.1);
    background: rgba(255, 255, 255, 0.2);
    //border-left: 3px solid #DF691A;
    font-weight: 600;
    margin-top: 0;

    &:not(:first-child) {
      border-top-width: 0;
    }

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      width: 4px;
      content: "";
      background-color: $primary;
    }
  }
  :global(.list-group-item:focus) {
    //background: #4E5D6C;
  }
  :global(.list-group-item:hover) {
    background: rgba(255, 255, 255, 0.2);
  }
}