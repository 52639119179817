@import "styles/variables";

.pic {
  width: 3rem;
  height: 3rem;
  margin-bottom: 1.5rem;
}

.comment {
  resize: none;
}